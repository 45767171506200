<template>
  <div class="container pd100">
    <div v-if="address" class="address-box mb10" @click="onAddressChoose">
      <div class="address-box-hd">
        <div class="address-box-inner">
          <van-icon name="location-o" class="address-box-inner-icon" />
          <div class="address-box-inner-title">收货人：{{ address.name }}</div>
          <div class="address-box-inner-title">{{ address.tel }}</div>
        </div>
        <div class="address-box-inner-bottom">收货地址：{{ address.address }}</div>
      </div>
      <div class="address-box-bd"><van-icon name="arrow" /></div>
    </div>
    <van-cell v-else class="address-card mb10" title="新增收货地址" icon="add-square" is-link @click="onAddressChoose" />

    <div class="order-info">
      <van-cell-group>
        <van-cell title="商品列表" />
        <van-card
          v-for="(item,index) in cartList"
          :key="index"
          :num="item.count"
          :desc="item.specname"
          :price="item.sale_price"
          :origin-price="item.market_price"
          :title="item.product_name"
          :thumb="item.catimg"
        />
        <van-cell title="优惠券" :value="hasDiscount ? '-'+coupon.coupon_dis_amount : '使用优惠'" is-link to="/usable-coupon" />

        <van-cell v-if="isNeedLogistics" title="配送方式" value="快递" />
      </van-cell-group>
      <!-- <van-cell-group>
  <van-field
    class="mb10"
    v-model="remark"
    label="买家留言"
    type="textarea"
    placeholder="点击给商家留言"
    rows="1"
    autosize
  />
</van-cell-group> -->
      <!-- <van-cell-group class="mb20">
   <van-cell title="支付工具"></van-cell>
   <van-cell title="余额" :label="'账户余额：￥'+balance+'元'" size="large">
       <template slot="default">
<van-switch
  v-model="isBalancePay"
  active-color="#f44"
  inactive-color="#d7d7d7"
/>
  </template>
   </van-cell>

</van-cell-group> -->
      <!-- <van-cell title="商品金额" :value="'¥'+goodsPrice"></van-cell> -->
      <!-- <van-cell v-if="isNeedLogistics" title="运费" value="+0.00"></van-cell> -->
      <!-- <van-cell v-if="hasDiscount" title="优惠" :value="'-'+coupon.coupon_dis_amount"></van-cell> -->
    </div>
    <van-submit-bar
      button-text="提交订单"
      @submit="onSubmit"
    >
      <div slot="default" class="submit-bar-left">
        <span>合计：</span>
        <span class="submit-bar-price">¥ {{ totalPrice }}</span>
      </div>
    </van-submit-bar>
  </div>
</template>

<script>

import { Card, Field, CouponCell, CouponList, SubmitBar, Switch } from 'vant'
// import { pay_balance } from '@/common/pay'
import { wxPay } from '@/common/wx'
import { isEmpty } from '@/common/validate'
import storage from 'good-storage'

export default {
  name: 'OrderSumit',
  components: {
    [Card.name]: Card,
    [Field.name]: Field,
    [CouponCell.name]: CouponCell,
    [CouponList.name]: CouponList,
    [SubmitBar.name]: SubmitBar,
    [Switch.name]: Switch
  },
  data() {
    return {

      address: null, // 使用address组件的数据格式
      cartList: [],
      hasDiscount: false,
      coupon: null,

      remark: '',
      chosenCoupon: -1,
      coupons: [],
      disabledCoupons: [],
      showList: false,
      isNeedLogistics: true, // 是否需要物流信息
      isBalancePay: true, //  钱包支付
      balance: 0,
      expireMinutes: 60,
      marketing: {}
    }
  },
  computed: {
    goodsPrice() {
      const price = this.cartList.reduce((total, item) => total + item.sale_price * item.count, 0)
      return price > 0 ? parseFloat(price).toFixed(2) : '0.00'
    },
    totalPrice() {
      const discount = this.hasDiscount ? Number(this.coupon.coupon_dis_amount) : 0
      const price = this.cartList.reduce((total, item) => total + item.sale_price * item.count, 0) - discount
      return price > 0 ? parseFloat(price).toFixed(2) : '0.00'
    }
  },
  created() {
    // 立即购买buy,加入购物车cart,
    // if (this.$route.query.type === 'buy') {
    //   this.cartList = sessionStorage.get('buyInfo') || []
    //   this.marketing = this.cartList[0].marketing
    // } else {
    //   let cartInfo = storage.get('cartInfo'); const checkedGoods = this.$route.query.checkedGoods || []
    //   cartInfo = checkedGoods.length ? cartInfo.filter(item => checkedGoods.indexOf(item.id) !== -1) : cartInfo
    //   this.cartList = cartInfo
    // }
    // // 默认地址
    // if (!this.address.name) {
    //   this.getaddress()
    // }
    // // 账户余额
    // this.getUserAmount()

    // if (this.marketing.type) {
    //   this.expireMinutes = 0
    // }
    // if (!this.marketing.type) {
    //   this.getMyDiscounts()
    // }

    // 商品数据取自sessionStorage( 立即购买buy, 加入购物车cart)
    // this.cartList = this.$route.query.type === 'buy' ? storage.session.get('buyInfo') : storage.session.get('cartInfo', [])
    this.queryCart()
    // 默认地址
    this.setAddress()
    // 优惠券
    this.setCoupon()
  },
  methods: {
    onAddressChoose() {
      this.$router.push({ path: '/address-list', query: { isChoose: true }})
    },
    setAddress() {
      const address = storage.session.get('choseAddress', null)
      if (address) {
        this.address = address
      } else {
        this.$api.address_default()
          .then(res => {
            if (isEmpty(res.data)) {
              // 无默认地址
              this.address = address
            } else {
              this.address = { ...res.data, id: res.data.aid, tel: res.data.phone, address: `${res.data.province}${res.data.city}${res.data.dist}${res.data.addrs}` }
            }
          })
      }
    },
    setCoupon() {
      const coupon = storage.session.get('choseCoupon', null)
      if (coupon) {
        this.hasDiscount = true
      } else {
        this.hasDiscount = false
      }
      this.coupon = coupon
    },
    queryCart() {
      this.$toast.loading({
        message: '加载中...',
        forbidClick: true
      })
      this.$api.cart_get()
        .then(res => {
          this.$toast.clear()
          this.cartList = res.data.cart_datas
        })
    },
    onSubmit() {
      if (!this.address) {
        this.$toast({ message: '地址栏不能为空', duration: 1500 })
        return
      }
      // 提交订单
      this.$toast.loading({
        mask: true,
        message: '订单创建中...',
        duration: 0
      })
      const params = {
        aid: this.address.id
      }
      this.coupon && this.coupon.coupon_id && (params.coupon_id = this.coupon.coupon_id)
      this.$api.order_createorder(params)
        .then(res => {
          // 调用微信支付
          const orderId = res.data.order_id
          const couponId = this.coupon && this.coupon.coupon_id ? this.coupon.coupon_id : ''
          // this.paymentStore(orderId, couponId)
          localStorage.setItem('money',Number(this.totalPrice)*100)
          this.$router.push({path:'/orderCenter',query:{toPath:`/order-detail?order_id=${res.data.order_id}`,out_trade_no:res.data.out_trade_no}})
        })
        .catch(err => {
          console.error(err)
        })
        .finally(() => {
          this.$toast.clear()
          storage.session.remove('choseAddress')
          storage.session.remove('choseCoupon')
        })
    },
    paymentStore(orderId, couponId = '') {
      this.$toast.loading({
        mask: true,
        message: '支付中...',
        duration: 0
      })
      const params = {
        order_id: orderId
      }
      couponId && (params.coupon_id = couponId)
      this.$api.payment_store(params)
        .then(res => {
          const { appId, timeStamp, nonceStr, package: packageStr, signType, paySign } = res.data.jsApiParameters
          wxPay( appId, timeStamp, nonceStr, packageStr, signType, paySign)
            .then(res => {
              this.$toast.success('支付成功')
              setTimeout(() => {
                this.redirectPage('success', orderId)
              }, 1000)
            })
            .catch(err => {
              this.$toast.fail('支付失败')
              setTimeout(() => {
                this.redirectPage('fail')
              }, 1500)
              console.error(err)
            })
        })
        .catch(err => {
          console.error(err)
        })
    },
    redirectPage(status, orderId = '') {
      // status success,fail
      this.$router.push({ path: '/order-result', query: { status, order_id: orderId }})
    }
  }
}
</script>

<style lang="less" scoped>
  .address-box{
    position: relative;
    box-sizing: border-box;
    min-height: 60px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding:8px 15px;
    background: #fff;
    &-hd{
      flex: 1;
      // padding:0 10px;
      padding-left: 20px;
    }
    &-bd{
      padding-left: 5px;

    }
    &-inner{
      position: relative;
      display: flex;
      justify-content: space-between;
      align-items: center;
      &-icon{
        position: absolute;
        top:2px;
        left: -20px;
      }
      &-title{
        font-size: 16px;
        color: #333;
        font-weight: bold;
        margin-bottom: 5px;
      }
      &-bottom{
        font-size: 12px;
        color: #666;
      }
    }
    &:before{
      position: absolute;
      right: 0;
      bottom: 0;
      left: 0;
      height: 2px;
      background: -webkit-repeating-linear-gradient(135deg, #ff6c6c 0, #ff6c6c 20%, transparent 0, transparent 25%, #00de93 0, #00de93 45%, transparent 0, transparent 50%);
      background: repeating-linear-gradient(-45deg, #ff6c6c 0, #ff6c6c 20%, transparent 0, transparent 25%, #00de93 0, #00de93 45%, transparent 0, transparent 50%);
      background-size: 80px;
      content: '';
    }
  }
  .address-card{
    position: relative;
    padding: 10px 15px;
    align-items: center;
    &:before{
      position: absolute;
      right: 0;
      bottom: 0;
      left: 0;
      height: 2px;
      background: -webkit-repeating-linear-gradient(135deg, #ff6c6c 0, #ff6c6c 20%, transparent 0, transparent 25%, #00de93 0, #00de93 45%, transparent 0, transparent 50%);
      background: repeating-linear-gradient(-45deg, #ff6c6c 0, #ff6c6c 20%, transparent 0, transparent 25%, #00de93 0, #00de93 45%, transparent 0, transparent 50%);
      background-size: 80px;
      content: '';
    }
  }
  .address-card .van-cell__left-icon{
    color: #00de93;
    font-size: 40px;
  }
  .address-card .van-cell__title{
    line-height: 40px;
  }
  .order-info{
    box-sizing: border-box;
    width:100%;
    // padding:0 15px;
    // background: #fff;
  }
  .submit-bar-left{
    flex: 1;
    padding-right: 12px;
    color: #323233;
    font-weight: 500;
    text-align: right;
  }
  .submit-bar-left span{
    display: inline-block;
  }
  .submit-bar-price{
    color: #f44;
    font-size: 18px;
  }

</style>

